<template>
  <v-container>
    <!-- ************************ tabs  ******************************* -->
    <v-layout row>
      <div class="col-12 col-md-3">
        <v-btn
          class="nav-btn ma-1"
          outlined
          large
          color="green"
          dark
          @click="codesUpload = true"
        >
          <v-icon>mdi-file-upload </v-icon> فایل کد انبار
          <v-icon>mdi-file-download </v-icon>
        </v-btn>
        <v-dialog v-model="codesUpload" max-width="600px">
          <v-flex>
            <v-card>
              <v-col md="12">
                <v-card-text>
                  <h4 style="color: white">فایل کدهای انبار</h4>
                  <v-file-input
                    v-model="refInput"
                    ref="refInput"
                    show-size
                    :rules="excelFileRules"
                    accept=".xls, .xlsx"
                    label="حداکثر 5 مگابایت - فقط xls , xlsx"
                  ></v-file-input>
                  <a
                    href="/api/downloadAnbar"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="text-decoration:none"
                  >
                    <v-btn
                      :loading="uploadLoading"
                      color="primary"
                      >دانلود فایل کدهای انبار
                      <v-icon>mdi-download </v-icon>
                    </v-btn>
                  </a>
                  <v-btn
                    style="float: left"
                    @click="uploadFiles('codes')"
                    :loading="uploadLoading"
                    color="primary"
                    >ارسال
                    <v-icon>mdi-upload </v-icon>
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-card>
          </v-flex>
        </v-dialog>
      </div>
      <div class="col-12 col-md-3">
        <v-btn
          class="nav-btn ma-1"
          outlined
          large
          color="primary"
          dark
          @click="upload = true"
        >
          <v-icon medium>mdi-file-upload </v-icon>آپلود فایل مرجع
        </v-btn>
        <v-dialog v-model="upload" max-width="600px">
          <v-flex>
            <v-card>
              <v-col md="12">
                <v-card-text>
                  <h4 style="color: white">فایل مرجع</h4>
                  <v-file-input
                    v-model="refInput"
                    ref="refInput"
                    show-size
                    :rules="excelFileRules"
                    accept=".xls, .xlsx"
                    label="حداکثر 5 مگابایت - فقط xls , xlsx"
                  ></v-file-input>
                  <v-btn
                    @click="uploadFiles('uploadFile')"
                    :loading="uploadLoading"
                    color="primary"
                    >ارسال</v-btn
                  >
                </v-card-text>
              </v-col>
            </v-card>
          </v-flex>
        </v-dialog>
      </div>
      <div class="col-12 col-md-3">
        <v-btn
          class="nav-btn ma-1"
          outlined
          large
          color="primary"
          dark
          @click="inputUpload = true"
        >
          <v-icon medium>mdi-file-upload </v-icon>آپلود فایل ورودی
        </v-btn>
        <v-dialog v-model="inputUpload" max-width="600px">
          <v-flex>
            <v-card>
              <v-col md="12">
                <v-card-text>
                  <h4 style="color: white">ورودی دیجیکالا</h4>
                  <v-file-input
                    v-model="refInput"
                    ref="refInput"
                    multiple
                    show-size
                    :rules="excelFilesRules"
                    accept=".xls, .xlsx"
                    label="حداکثر 5 مگابایت - فقط xls , xlsx"
                  ></v-file-input>
                  <v-btn
                    @click="uploadDigiFiles()"
                    :loading="uploadLoading"
                    color="primary"
                    >ارسال</v-btn
                  >
                </v-card-text>
              </v-col>
            </v-card>
          </v-flex>
        </v-dialog>
      </div>
      <div class="col-12 col-md-3">
        <a
          href="/api/download"
          target="_blank"
          rel="noopener noreferrer"
          style="text-decoration:none"
        >
          <v-btn
            class="nav-btn ma-1"
            width="100%"
            color="primary"
            outlined
            large
            dark
          >
            <v-icon>mdi-download</v-icon>دانلود فایل
          </v-btn>
        </a>
      </div>
      <router-view />
    </v-layout>

    <!-- ***************************************** -->
    <v-layout>
      <v-row>
        <v-progress-linear
          :active="loading"
          indeterminate
          color="yellow darken-2"
          class="w-100"
        ></v-progress-linear>
        <v-card class="w-100">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab v-for="item in sheetNames" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <v-data-table
          :headers="[
            ...headers,
            {
              text: 'حذف',
              value: 'actions',
              sortable: false,
            },
          ]"
          :search="search"
          :items="myItem"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="جستجو ..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-toolbar flat>
              <v-toolbar-title
                >لیست محصولات: {{ sheetNames[tab] }}</v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                @click="deleteSheet"
                color="red"
                dark
                outlined
                class="mb-2"
              >
                <v-icon>mdi-delete </v-icon>
                حذف گروه {{ sheetNames[tab] }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editedItem = {}"
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    سطر جدید
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="item in headers"
                          :key="item.value"
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="editedItem[item.value]"
                            :label="item.value"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="close">
                      انصراف
                    </v-btn>
                    <v-btn
                      :loading="loading"
                      color="green darken-1"
                      text
                      @click="save"
                    >
                      ذخیره
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >آیا از حذف کالا مطمئن هستید؟</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogDelete = false"
                      >انصراف</v-btn
                    >
                    <v-btn
                      color="red darken-1"
                      :loading="loading"
                      text
                      @click="deleteItemConfirm"
                      >بله</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="editItem(item)" color="green">
              mdi-pencil
            </v-icon>
            <v-icon small class="mt-5" @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: [],
  mounted() {
    this.shItems(0);
  },
  data: () => ({
    files: "",
    uploadLoading: false,
    loading: true,
    search: "",
    dialog: false,
    upload: false,
    inputUpload: false,
    codesUpload: false,
    dialogDelete: false,
    refInput: null,
    itemsList: [],
    headersList: [],
    headers: [],
    myItem: [],
    sheetNames: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    tab: 0,
    excelFileRules: [
      (value) =>
        !value ||
        value.size <= 5000000 ||
        "حجم هر فایل باید کمتر از 5 مگابایت باشد!",
    ],
    excelFilesRules: [
      (value) => {
        var error = false;
        value.forEach((element) => {
          error =
            !element ||
            element.size <= 5000000 ||
            "حجم هر فایل باید کمتر از 5 مگابایت باشد!";
        });
        return error;
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "کالای جدید" : "ویرایش کالا  ";
    },
  },
  watch: {
    tab(val) {
      this.shItems(val);
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    uploadDigiFiles() {
      let list = new FormData();
      if (!this.refInput) {
        alert("لطفا فایل های ورودی را انتخاب کنید!");
        return;
      }
      for (var i = 0; i < this.refInput.length; i++) {
        list.append("refInput", this.refInput[i]);
      }
      this.uploadLoading = true;
      this.$axios
        .post("api/digiInput", list, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          alert(data);
          this.inputUpload = false;
          this.shItems(0);
          // clear file input
          this.refInput = null;
        })
        .finally((e) => (this.uploadLoading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    // Insert Excel file Into DB
    uploadFiles(endPoint) {
      // send xls file to DB with post
      let list = new FormData();
      if (!this.refInput) {
        alert("لطفا فایل را انتخاب کنید!");
        return;
      }
      list.append("refInput", this.refInput);
      this.uploadLoading = true;

      // ersal form data be samt server
      this.$axios
        .post("api/" + endPoint, list)
        .then(({ data }) => {
          alert(data);
          this.upload = false;
          this.codesUpload = false;
          this.shItems(0);
          // clear file input
          this.refInput = null;
        })
        .finally((e) => (this.uploadLoading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    shItems(item) {
      this.loading = true;
      this.myItem = [];
      this.$axios
        .get("api/list/" + item)
        .then(({ data }) => {
          this.myItem = data.data;
          this.headers = data.headers;
          this.headers.push();
          this.sheetNames = data.sheetList;
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    initialize() {
      this.myItem = this.itemsList;
      this.headers = this.headersList;
    },
    deleteItem(item) {
      this.editedIndex = this.myItem.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.myItem.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    deleteItemConfirm(itemCode) {
      this.loading = true;
      this.$axios
        .delete("api/list/" + this.tab, {
          data: { itemCode: this.myItem[this.editedIndex]["کد تنوع"] },
        })
        .then(({ data }) => {
          this.$nextTick(() => {
            this.myItem.splice(this.editedIndex, 1);
            this.shItems(this.tab);
            // this.myItem = Object.assign({}, this.defaultItem);
            // this.editedIndex = -1;
            this.dialogDelete = false;
          });
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    save() {
      this.loading = true;
      this.$axios({
        method: this.editedIndex > -1 ? "put" : "post",
        url: "api/newItem/" + this.tab,
        data: this.editedItem,
      })
        .then(({ data }) => {
          this.shItems(this.tab);
          // if (this.editedIndex > -1) {
          //   Object.assign(this.myItem[this.editedIndex], this.editedItem);
          // } else {
          //   this.myItem.push(this.editedItem);
          // }
          this.editedIndex = -1;
          this.close();
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    
    deleteSheet() {
      this.loading = true;
      this.$axios
        .delete("api/sheet/" + this.tab)
        .then(({ data }) => {
          this.shItems(0);
        })
        .finally((e) => (this.loading = false))
        .catch((e) => {
          console.log(e);
        });
    },
    edit() {},
  },
};
</script>
<style>
.v-file-input {
  margin-top: 0px;
  margin-right: 15px;
  width: 100%;
}
.body {
  width: 100%;
}
.v-text-field {
  margin-right: 15px;
  width: 100%;
}
.v-btn {
  letter-spacing: 0;
}
.nav-btn {
  width: 96.2%;
}
#cancel {
  margin-left: 15px;
}
.projecList {
  width: 100%;
}
.downloadBtn {
  width: 100%;
  margin-top: 10px;
  margin-left: 6%;
  text-decoration: none;
}
.tabelsHeader {
  min-width: 550px;
}
.changeProjectName {
  margin-left: 5%;
}
.w-100 {
  width: 100%;
}
</style>
